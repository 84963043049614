import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>About Us</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="why-choose-one">
          <div className="shape3 float-bob-x">
            <img src="assets/images/shapes/why-choose-v1-shape2.png" alt="#" />
          </div>
          <div className="shape5">
            <img src="assets/images/shapes/why-choose-v1-shape3.png" alt="#" />
          </div>
          <div className="shape6">
            <img src="assets/images/shapes/why-choose-v1-shape4.png" alt="#" />
          </div>
          <div className="shape7">
            <img src="assets/images/shapes/why-choose-v1-shape5.png" alt="#" />
          </div>
          <div className="container">
            <div className="row">
              {/*Start Why Choose One Img*/}
              <div className="col-xl-6">
                <div className="why-choose-one__img">
                  <div className="shape2" />
                  <div className="shape1">
                    <img
                      src="assets/images/shapes/why-choose-v1-shape1.png"
                      alt="#"
                    />
                  </div>
                  <div className="shape4 float-bob-y">
                    <img
                      src="assets/images/shapes/why-choose-v1-shape2.png"
                      alt="#"
                    />
                  </div>
                  <div className="why-choose-one__img1">
                    <img
                      src="assets/images/About3.jpg"
                      style={{ height: "670px" }}
                      alt="#"
                    />
                  </div>
                  <div
                    className="why-choose-one__img2 wow fadeInRight"
                    data-wow-delay="100ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="inner">
                      <img
                        src="assets/images/About4.jpg"
                        style={{ height: "400px" }}
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*End Why Choose One Img*/}
              {/*Start Why Choose One Content*/}
              <div className="col-xl-6">
                <div className="why-choose-one__content">
                  <div className="sec-title">
                    <h2 className="sec-title__title">
                      Making the World Smaller, Cargo Bigger
                    </h2>
                  </div>
                  <div className="why-choose-one__content-text">
                    <p className="text1">Welcome to {companyname}</p>
                    <p className="text2">
                      {companyname} is an international freight forwarding
                      company, headquartered in Singapore. Our primary mission
                      is to serve the shipping sector by offering a wide range
                      of services, spanning from supplies to maintenance and
                      operations. We have dedicated significant efforts to
                      establish a comprehensive network of high-quality
                      strategic partnerships on a global scale. Our expertise
                      lies in various aspects of the shipping industry,
                      encompassing air, sea, and road transportation.
                    </p>
                  </div>

                  <div className="why-choose-one__content-text2">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="why-choose-one__content-text2-single">
                          <div className="inner">
                            <div className="icon-box">
                              <span className="fa fa-plane" />
                            </div>
                            <div className="title-box">
                              <h2>
                                Amazing <br />
                                Freight Services
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="why-choose-one__content-text2-single">
                          <div className="inner">
                            <div className="icon-box">
                              <span className="fa fa-clock" />
                            </div>
                            <div className="title-box">
                              <h2>24/7 Support</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              {/*End Why Choose One Content*/}
            </div>
          </div>
        </section>
        <section className="therapy-one">
          <div className="container">
            <div className="therapy-one__inner">
              <div
                className="therapy-one__pattern"
                style={{
                  backgroundImage:
                    "url(assets/images/pattern/therapy-v1-pattern.png)",
                }}
              />
              <div
                className="therapy-one__bg"
                style={{
                  backgroundImage: "url(assets/images/About2.jpg)",
                }}
              />
              <div
                className="therapy-one__img1 wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <img
                  src="assets/images/resources/therapy-v1-img1.png"
                  alt="#"
                />
              </div>
              <div className="shape1" />
              <div className="shape2" />
              <div className="shape3" />
              <div className="row">
                {/*Start Therapy One Left*/}
                <div className="col-xl-5">
                  <div className="therapy-one__left">
                    <div className="sec-title">
                      <div className="sec-title__tagline">
                        <h6>Why Choose {companyname}</h6>
                      </div>
                      <h2 className="sec-title__title">
                        Delivering More than Freight, Delivering Trust
                      </h2>
                    </div>
                    <ul className="therapy-one__left-list">
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="content-box">
                          <p>
                            Despite being a relatively new entrant in the
                            industry, every individual within the {companyname} team
                            nurtures a profound aspiration to become a true
                            expert within this domain. We enthusiastically
                            invite and eagerly await your invaluable inquiries.
                            Our commitment extends beyond mere words; it is an
                            unwavering dedication to providing you with the
                            highest caliber of freight forwarding customer
                            service. Our aim is to leverage our growing
                            experience and extensive knowledge to assist you in
                            making well-informed decisions that not only
                            optimize time but also minimize costs, ensuring the
                            most efficient and cost-effective choices for your
                            shipping needs.
                          </p>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                {/*End Therapy One Left*/}
                {/*Start Therapy One Right*/}
                <div className="col-xl-7">
                  <div className="therapy-one__right clearfix">
                    <div className="therapy-one__right-content">
                      <div
                        className="therapy-one__right-content-bg"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/therapy-v1-bg2.jpg)",
                        }}
                      ></div>
                      <div className="inner">
                        <div className="content-box">
                          <h2>Navigating Challenges with Ease</h2>
                          <p>
                            our commitment to proactive problem-solving and
                            adaptability, ensuring smooth progress in a dynamic
                            business landscape.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Therapy One Right*/}
              </div>
            </div>
          </div>
        </section>
        {/*End Therapy One */}

      <Footer />
    </>
  );
}
