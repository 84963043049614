import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Our Services</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Our Services</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="services-one">
          <div className="container">
            <div className="row">
              {/*Start Services One Single*/}
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Air Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-plane" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Air">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Air">Air Freight</Link>
                      </h2>
                      <p> We have the capability to organize your shipments
                     globally, utilizing various modes of transportation, and
                     providing expert advice on the most cost-effective and...</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Sea Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-ship" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Sea">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Sea">Sea Freight</Link>
                      </h2>
                      <p> We have the capability to arrange both Full Container Load
                      (FCL) and Less than Container Load (LCL) shipments for our
                      global customers. Our significant advantage lies in our...</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Surface Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-truck" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Surface">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Surface">Surface Freight</Link>
                      </h2>
                      <p> Our expertise extends to arranging the transportation of
                      both Full Truck Loads (FTL) and Less Than Truck Loads
                      (LTL) across Singapore, leveraging cutting-edge tracking...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="case-one">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <h6>All Services</h6>
              </div>
              <h2 className="sec-title__title">Our Freight Solutions</h2>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="case-one__inner">
                  <div
                    className="owl-carousel owl-theme thm-owl__carousel case-one__carousel"
                    data-owl-options='{
                                "loop": true,
                                "autoplay": true,
                                "margin": 30,
                                "nav": false,
                                "dots": true,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-next\"></span>"],
                                "responsive": {
                                        "0": {
                                            "items": 1
                                        },
                                        "768": {
                                            "items": 2
                                        },
                                        "992": {
                                            "items": 2
                                        },
                                        "1200": {
                                            "items": 4
                                        }
                                    }
                                }'
                  >
                    {/*Start Case One Single*/}
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Air Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Air">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Air">Air Freight</Link>
                          </h2>
                          <p> We have the capability to organize your shipments
                     globally, utilizing various modes of transportation, and
                     providing expert advice on the most cost-effective and...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Sea Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Sea">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Sea">Sea Freight</Link>
                          </h2>
                          <p> We have the capability to arrange both Full Container Load
                      (FCL) and Less than Container Load (LCL) shipments for our
                      global customers. Our significant advantage lies in our...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Surface Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Surface">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Surface">Surface Freight</Link>
                          </h2>
                          <p> Our expertise extends to arranging the transportation of
                      both Full Truck Loads (FTL) and Less Than Truck Loads
                      (LTL) across Singapore, leveraging cutting-edge tracking...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Custom Clearance.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Custom">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Custom">Custom Clearance</Link>
                          </h2>
                          <p> We possess extensive expertise in customs clearance,
                      covering both Import and Export operations to and from all
                      Ports and Inland Container Depots (ICDs) in Singapore...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Courier Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Courier">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Courier">Courier Freight</Link>
                          </h2>
                          <p>  We offer comprehensive courier services, ensuring the
                      swift transportation of our customers' small cargo from
                      one door to another anywhere in the world. Our unique...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img
                          src="assets/images/Warehousing & Packaging.jpg"
                          alt="#"
                        />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Warehousing">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Warehousing">
                              Warehousing & Packaging
                            </Link>
                          </h2>
                          <p> We provide a comprehensive, one-stop solution for
                      essential warehouse services, strategically located in
                      proximity to automotive, engineering, pharmaceutical, and...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </>
  );
}
