import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Food & Beverages</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Food & Beverages</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            {/*Start Blog Details Content*/}
            <div className="col-xl-8">
              <div className="blog-details__content">
                <div className="blog-list-page__single">
                  <div className="blog-list-page__single-img">
                    <img src="assets/images/Food & Beverages.jpg" alt="#" />
                    <div className="overlay-text">Industry</div>
                  </div>
                  <div className="blog-list-page__single-content">
                    <div className="blog-list-page__single-content-top"></div>
                    <h2>Food & Beverages</h2>
                  </div>
                </div>
                <div className="blog-details__content-text1">
                  <p className="text1">
                    {" "}
                    Meat, seafood, fruits, vegetables, and beverages like
                    coffee, wine, and spirits are among the most sought-after
                    commodities globally. Nevertheless, they also pose some of
                    the most intricate challenges when it comes to
                    transportation, primarily due to factors like limited
                    product shelf-life and the unpredictability of seasonal
                    harvests.
                    <p>
                      With its extensive decades-long experience in catering to
                      the food and beverage industries, {companyname} possesses an
                      in-depth understanding of the sector's distinctive
                      requirements. We recognize the criticality of providing
                      comprehensive global port coverage, ensuring the
                      availability of suitable equipment, and maintaining
                      dependable scheduling practices. Our commitment to meeting
                      these specific needs sets us apart as a trusted partner in
                      the complex world of food and beverage logistics, ensuring
                      the safe and timely delivery of these precious commodities
                      to consumers worldwide.
                    </p>{" "}
                  </p>
                </div>
              </div>
            </div>
            {/*End Blog Details Content*/}
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}

                <div
                  className="sidebar__single sidebar__category wow animated fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="title-box">
                    <h2>Other Industries</h2>
                  </div>
                  <ul className="sidebar__category-list list-unstyled">
                    <li className="">
                      <Link to="/Automotive">Automotive </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Chemicals">Chemicals & Petrochemicals </Link>
                    </li>
                    <li className=" active">
                      <Link to="/Food">Food & Beverages </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Fruits">Fruits & Vegetables </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Mining">Mining & Minerals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Pharmaceuticals">Pharmaceuticals </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
