import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Surface Freight</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Surface Freight</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="services-details">
        <div className="container">
          <div className="row">
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}
                <div
                  className="sidebar__single sidebar__services wow animated fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="title-box">
                    <h2>Services List</h2>
                  </div>
                  <div className="sidebar__services-box">
                    <ul className="sidebar__services-box-list clearfix">
                      <li>
                        <Link className="" to="/Air">
                          Air Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Sea">
                          Sea Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" active" to="/Surface">
                          Surface Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Custom">
                          Custom Clearance
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Courier">
                          Courier Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Warehousing">
                          Warehousing & Packaging
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
            {/*Start Services Details Content*/}
            <div className="col-xl-8">
              <div className="services-details__content">
                <div className="services-details__content-text1">
                  <h2>Surface Freight</h2>

                  <div className="img-box">
                    <img src="assets/images/Surface Freight.jpg" alt="#" />
                  </div>
                  <p className="text2">
                    {" "}
                    <p>
                      Our expertise extends to arranging the transportation of
                      both Full Truck Loads (FTL) and Less Than Truck Loads
                      (LTL) across Singapore, leveraging cutting-edge tracking
                      technologies for vehicle monitoring. We take pride in
                      offering a comprehensive point-to-point delivery service,
                      ensuring our customers have convenient delivery options
                      regardless of their location. Our commitment is to guide
                      our customers in selecting the most cost-effective and
                      reliable routes to efficiently deliver their valuable
                      orders to their clients, prioritizing both economy and
                      speed.
                    </p>
                    <p>Our Service Details:</p>
                    <ul>
                      <li>Closed and Open trailers</li>
                      <li>Door-to-Door services</li>
                      <li>Full truck loads for 20' & 40'</li>
                      <li>General & Heavy lift truck</li>
                      <li>Less than truck loads</li>
                      <li>Refrigerated truck</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            {/*End Services Details Content*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
