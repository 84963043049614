import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Frequently Asked Questions</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Frequently Asked Questions</li>
            </ul>
          </div>
        </div>
      </section>

      {/*Faq Search Box End*/}
      {/*Start Faq Page*/}
      <section className="faq-page">
        <div className="container">
          <div className="row">
            {/*Start Faq Page Left*/}
            <div className="col-xl-12 col-lg-12">
              <div className="faq-page__left">
                <div className="title">
                  <h2>Frequently Asked Questions</h2>
                </div>
                <div className="services-details__faq services-details__faq--faq-page">
                  <div
                    className="accrodion-grp"
                    data-grp-name="faq-one-accrodion"
                  >
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>What is a freight forwarding company?</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            A freight forwarding company is a third-party
                            logistics provider that specializes in coordinating
                            the transportation and shipment of goods on behalf
                            of shippers or businesses. They manage various
                            aspects of the supply chain, including shipping,
                            customs clearance, documentation, and logistics
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>What is a freight forwarding company?</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            A freight forwarding company is a third-party
                            logistics provider that specializes in coordinating
                            the transportation and shipment of goods on behalf
                            of shippers or businesses. They manage various
                            aspects of the supply chain, including shipping,
                            customs clearance, documentation, and logistics
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>
                          How do freight forwarders handle shipping
                          documentation?
                        </h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            Freight forwarders are responsible for preparing and
                            managing shipping documentation, including bills of
                            lading, invoices, packing lists, certificates of
                            origin, and customs declarations. Accurate
                            documentation is crucial for efficient shipment and
                            customs clearance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>
                          What services do freight forwarding companies offer?
                        </h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            Freight forwarding companies offer a range of
                            services, including international and domestic
                            shipping, customs brokerage, cargo insurance,
                            warehousing, order tracking, documentation
                            preparation, and freight consolidation. They ensure
                            smooth movement of goods from the point of origin to
                            the final destination.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>Do freight forwarders provide cargo insurance?</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            Many freight forwarders offer cargo insurance to
                            protect shipments against loss or damage during
                            transit. However, insurance terms and coverage may
                            vary, so it's important to discuss insurance options
                            with your chosen forwarder.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>
                          How do freight forwarders help with customs clearance?
                        </h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            Freight forwarders assist in preparing the necessary
                            customs documentation, ensuring compliance with
                            regulations and tariffs, and handling communication
                            with customs authorities. They help facilitate the
                            smooth clearance of goods across international
                            borders.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>
                          Can freight forwarders handle oversized or hazardous
                          cargo?
                        </h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            Yes, some freight forwarders specialize in handling
                            specialized cargo such as oversized, heavy, or
                            hazardous materials. These forwarders have expertise
                            in managing the unique requirements and regulations
                            associated with such shipments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>
                          How do I choose the right freight forwarding company?
                        </h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            When choosing a freight forwarder, consider factors
                            such as their experience, industry reputation,
                            global network, services offered, customer support,
                            and cost-effectiveness. It's important to select a
                            company that aligns with your shipping needs and
                            requirements.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accrodion active">
                      <div className="accrodion-title">
                        <h4>What is door-to-door shipping?</h4>
                      </div>
                      <div className="accrodion-content">
                        <div className="inner">
                          <p>
                            Door-to-door shipping is a service where the freight
                            forwarder arranges transportation from the shipper's
                            location to the recipient's location. This includes
                            pickup, transportation, customs clearance, and final
                            delivery.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Faq Page Left*/}
          </div>
        </div>
      </section>
      {/*End Faq Page*/}

      <Footer />
    </>
  );
}
