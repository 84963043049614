import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Industries</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Industries</li>
            </ul>
          </div>
        </div>
      </section>
        {/*Start Team One*/}
        <section className="team-one">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <h6>Our Industries</h6>
              </div>
              <h2 className="sec-title__title">Explore Our Industries</h2>
            </div>
            <div className="row">
              {/*Start Team One Single*/}
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Automotive.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Automotive">
                      <div className="title-box text-center">
                        <h2>Automotive</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img
                      src="assets/images/Chemicals & Petrochemicals.jpg"
                      alt="#"
                    />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Chemicals">
                      <div className="title-box text-center">
                        <h2>Chemicals & Petrochemicals</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Food & Beverages.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Food">
                      <div className="title-box text-center">
                        <h2>Food & Beverages</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Fruits & Vegetables.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Fruits">
                      <div className="title-box text-center">
                        <h2>Fruits & Vegetables</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Mining & Minerals.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Mining">
                      <div className="title-box text-center">
                        <h2>Mining & Minerals</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Pharmaceuticals.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Pharmaceuticals">
                      <div className="title-box text-center">
                        <h2>Pharmaceuticals</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Team One*/}

      <Footer />
    </>
  );
}
