import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import React, { useState } from 'react';

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
    const [originOption, setOriginOption] = useState('');
    const [terminalOption, setTerminalOption] = useState('');
    const [distance, setDistance] = useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const calculatedDistance = calculateDistance(originOption, terminalOption);
      setDistance(calculatedDistance);
    };
  
    const calculateDistance = (origin, terminal) => {
      const eind = origin.split('/');
      const tourism = terminal.split('/');
      const d = Math.acos(Math.sin(eind[2]) * Math.sin(tourism[2]) +
        Math.cos(eind[2]) * Math.cos(tourism[2]) * Math.cos(eind[4] - tourism[4]));
      const a = Math.round(3956.073 * d);
      let b;
      if (Math.sin(tourism[4] - eind[4]) < 0) {
        b = Math.acos((Math.sin(tourism[2]) - Math.sin(eind[2]) * Math.cos(d)) /
          (Math.sin(d) * Math.cos(eind[2])));
      } else {
        b = 2 * Math.PI -
          Math.acos((Math.sin(tourism[2]) - Math.sin(eind[2]) * Math.cos(d)) /
            (Math.sin(d) * Math.cos(eind[2])));
      }
      return `${Math.round(a * 1.6094)} kms`;
    };
  

  
   
  return (
    <>
      <Header />
     
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Dangerous Goods Labels</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Dangerous Goods Labels</li>
            </ul>
          </div>
        </div>
      </section>
      <section id="main-container" className="main-container">
        <div className="container">
        <div className="title text-center pt-4">
              <h2>
              Classification of dangerous goods labels
              </h2>
              
            </div>
          <h3></h3><br/>
          <ul className="list-unstyled">
            <li><i className="fa fa-check" />&nbsp;&nbsp;<b>Dangerous goods</b> are chemicals that, because to their
              traits and make-up, may put people's health,
              safety, and the environment in danger. It is mandatory to adhere to a set of stringent guidelines for
              their handling, storage, and transportation.</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;<b>Dangerous goods labels</b> utilised to determine the
              risks associated with the products being
              transported. According to the international standard ADR 2019 (European Agreement on the Transport of
              Dangerous Goods by Road), goods are categorised depending on their make-up and level of danger.</li>
          </ul>
          <ul>
            <li>Class 1: Explosive substances and articles. All goods with a risk of causing an explosion, whether it is
              a mass explosion, a light fire, a blast wave, etc. Identified by a black icon or number on an orange
              background.</li>
            <li> Class 2: Gases. Classified into three subdivisions according to whether they are flammable (flame icon
              on a
              red background), non-flammable non-toxic (cylinder icon on a green background) or toxic (skull icon on a
              white background).</li>
            <li>Class 3: Flammable liquids. Materials with a maximum flash point of 60º C.</li>
            <li> Class 4: Flammable solids and other solid explosive substances. Subdivided into solid flammable goods
              (4.1
              white and red striped background), self-reactive substance (4.2 white/red background) and substances
              that
              release flammable gases in contact with water (4.3 blue background).</li>
            <li> Class 5: Oxidising substances and organic peroxides. Identified by the yellow background.</li>
            <li>Class 6: Toxic substances (which can be harmful to health and even fatal) or infectious substances
              (which
              contain micro-organisms such as bacteria or viruses). The label has a white background.</li>
            <li>Class 7: Radioactive substances. The label has a white or yellow/white background depending on the level
              of
              radioactivity of the goods.</li>
            <li> Class 8: Corrosive substances. Icon with two pipettes on a black and white background with the word
              “Corrosive”.</li>
            <li> Class 9: Miscellaneous dangerous substances. The label has black and white stripes at the top, with a
              white
              background on the bottom.</li>
          </ul>
          <section id="main-container" className="main-container">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                </div>
              </div>
              {/*/ Title row end */}
              <div className="row text-center">
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°1</p>
                      <img src="assets/images/1.jpg" />
                      <p>Explosives</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°2</p>
                      <img src="assets/images/2.jpg" />
                      <p>Flammable liquids. Fire hazard</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°3</p>
                      <img src="assets/images/3.jpg" />
                      <p>Flammable liquids. Fire hazard</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°4.1</p>
                      <img src="assets/images/4.jpg" />
                      <p>Flammable solids</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°4.2</p>
                      <img src="assets/images/5.jpg" />
                      <p>Substances liable to spontaneous combustion</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°4.3</p>
                      <img src="assets/images/6.jpg" />
                      <p>Flammable gas when in contact with water</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°4.4</p>
                      <img src="assets/images/7.jpg" />
                      <p>Flammable gas when in contact with water</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°5</p>
                      <img src="assets/images/8.jpg" />
                      <p>Oxidising substances and organic peroxides</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°6.1</p>
                      <img src="assets/images/9.jpg" />
                      <p>Toxic substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°6.1A</p>
                      <img src="assets/images/10.jpg" />
                      <p>Harmful substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°7A</p>
                      <img src="assets/images/11.jpg" />
                      <p>Radioactive substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°7B</p>
                      <img src="assets/images/12.jpg" />
                      <p>Radioactive substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°7C</p>
                      <img src="assets/images/13.jpg" />
                      <p>Radioactive substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°7D</p>
                      <img src="assets/images/14.jpg" />
                      <p>Radioactive substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6">
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°8</p>
                      <img src="assets/images/15.jpg" />
                      <p>Corrosive substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
                <div className="col-lg-4 col-md-6" style={{marginLeft: '380px'}}>
                  <div className="quote-item quote-border mt-5">
                    <div className="quote-text-border" style={{border: '3px solid #ffb600', padding: '20px', position: 'relative', lineHeight: '28px', color: '#666', fontSize: '18px', textAlign: 'center'}}>
                      <p>N°9</p>
                      <img src="assets/images/16.jpg" />
                      <p>Miscellaneous dangerous substances</p>
                    </div>
                  </div>{/* Quote item end */}
                </div>{/* End col md 4 */}
              </div></div></section>{/* Main container end */}
        </div>{/* Conatiner end */}
      </section>{/* Main container end */}

      <Footer />
    </>
  );
}
