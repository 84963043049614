import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Contact</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="contact-page">
        <div className="container">
          <div className="contact-page__top">
            <div className="title text-center">
              <h2>
                Feel free to ask questions or share your <br /> message with us.
              </h2>
              <p>
                Don't miss out on our expert logistics services. Fill up the
                form, and gain full control over your cargo's journey
              </p>
            </div>
            <ul className="list-unstyled">
              {/*Start Contact Page Top single*/}
              <li className="contact-page__top-single">
                <div className="icon">
                  <span className="icon-location" />
                </div>
                <div className="content">
                  <h2>Address</h2>
                  <p>{companyaddress}</p>
                </div>
              </li>
              {/*End Contact Page Top single*/}
              {/*Start Contact Page Top single*/}

              {/*End Contact Page Top single*/}
              {/*Start Contact Page Top single*/}
              <li className="contact-page__top-single">
                <div className="icon">
                  <span className="icon-email" />
                </div>
                <div className="content">
                  <h2>Email Address</h2>
                  <p>
                    <a href="mailto:{companyemail}">{companyemail}</a>
                  </p>
                </div>
              </li>
              {/*End Contact Page Top single*/}
            </ul>
          </div>
          <div className="contact-page__bottom">
            <div
              className="contact-page__bottom-pattern"
              style={{
                backgroundImage:
                  "url(assets/images/pattern/contact-pattern.jpg)",
              }}
            >
              {" "}
            </div>
            <div className="contact-page__bottom-inner">
              <form
                id="contactForm"
                className="contact-page__form contact-form-validated"
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-6">
                    <div className="contact-page__form-input-box">
                      <input
                        className="form-control"
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="contact-page__form-input-box">
                      <input
                        className="form-control"
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="contact-page__form-input-box">
                      <input
                        className="form-control"
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="contact-page__form-input-box">
                      <input
                        className="form-control"
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="contact-page__form-input-box">
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button className="thm-btn" type="submit" defaultValue="">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
        </div>
      </section>
      <section className="google-map">
        <iframe
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.818076646239!2d103.84794537412166!3d1.2829893617888801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190950b89a1f%3A0xd1e94e3181e1a87f!2sVOPlus%20-%20One%20Stop%20Corporate%20Services!5e0!3m2!1sen!2sin!4v1695290806942!5m2!1sen!2sin"
          className="google-map__one"
          allowFullScreen
        />
       
      </section>
      <Footer />
    </>
  );
}
