import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  function handleMobileNavClick() {
    const mobileNavWrapper = document.querySelector(".mobile-nav__wrapper");
    const mainHeader = document.querySelector(".mainheader"); // Replace with your actual class

    if (mobileNavWrapper && mainHeader) {
      mobileNavWrapper.classList.remove("expanded");
      mainHeader.classList.remove("locked"); // Remove the 'locked' class
    }
  }

  const mobileNavToggler = document.querySelector(".mobile-nav__toggler");

  if (mobileNavToggler) {
    mobileNavToggler.addEventListener("click", handleMobileNavClick);
  }
  useEffect(() => {
    const mobileNavToggler = document.querySelector(".mobile-nav__toggler");
    const mainHeader = document.querySelector("#mainheader");
    const mobileNavWrapper = document.querySelector(".mobile-nav__wrapper");

    if (mobileNavToggler && mainHeader && mobileNavWrapper) {
      mobileNavToggler.addEventListener("click", (e) => {
        e.preventDefault();
        mobileNavWrapper.classList.toggle("expanded");
        mainHeader.classList.toggle("locked");
      });
    }

    const mainMenuList = document.querySelector(".main-menu__list");
    const mobileNavContainer = document.querySelector(".mobile-nav__container");
    const stickyHeaderContent = document.querySelector(
      ".sticky-header__content"
    );

    if (mainMenuList && mobileNavContainer) {
      // Clone and append the main-menu__list to the mobile-nav__container
      const navContent = mainMenuList.outerHTML;
      mobileNavContainer.innerHTML = navContent;
    }

    if (stickyHeaderContent) {
      // Clone and append the content of .main-menu to .sticky-header__content
      const navContent = document.querySelector(".main-menu").innerHTML;
      stickyHeaderContent.innerHTML = navContent;
    }

    const dropdownAnchors = document.querySelectorAll(
      ".mobile-nav__container .main-menu__list .dropdown > a"
    );

    dropdownAnchors.forEach((anchor) => {
      const toggleBtn = document.createElement("button");
      toggleBtn.setAttribute("aria-label", "dropdown toggler");
      toggleBtn.innerHTML = '<i class="fa fa-angle-down"></i>';
      anchor.appendChild(toggleBtn);

      toggleBtn.addEventListener("click", (e) => {
        e.preventDefault();
        const self = anchor.querySelector("button");
        self.classList.toggle("expanded");
        anchor.parentElement.classList.toggle("expanded");
        anchor.parentElement.parentElement
          .querySelector("ul")
          .classList.toggle("expanded");
      });
    });
  }, []);
  return (
    <>
      <div className="mainheader " id="mainheader">
        {/*Start Main Header One*/}
        <header className="main-header main-header-one clearfix">
          <div className="main-header-one__top">
            <div className="container">
              <div className="main-header-one__top-inner">
                <div className="main-header-one__top-left">
                  <div className="main-header__contact-list">
                    <ul>
                      
                      <li>
                        <p>
                          {" "}
                          <span className="icon-location" /> Location : {companyaddress}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="main-header-one__top-right">
                  <div className="main-header-one__top-right-text">
                    <p>
                      <span className="icon-email1" /> Our Email :{" "}
                      <Link >{companyemail}</Link>
                    </p>
                  </div>
                  <div className="main-header-one__social-links">
                  
                   
                  </div>
                  <div className="main-header__top-right-btn">
                    <Link to="/Track" className="thm-btn">
                      Shipment Tracking
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-header-one__bottom">
            <nav className="main-menu clearfix">
              <div className="main-menu__wrapper clearfix">
                <div className="container">
                  <div className="main-header-one__bottom-inner">
                    <div className="main-header-one__bottom-left">
                      <div className="logo-one">
                        <Link to="/">
                          <img
                            src="assets/images/logo.png"
                            alt="#"
                            style={{height:"120px", paddingBottom:"5px"}}
                          />
                        </Link>
                      </div>
                      <div className="main-menu__main-menu-box">
                        <a href="#" className="mobile-nav__toggler">
                          <i className="fa fa-bars" />
                        </a>
                        <ul className="main-menu__list">
                        <li>
                            <Link to="/">Home</Link>
                          </li>
                         
                          <li className="dropdown">
                            <Link to="#">Company Profile</Link>
                            <ul>
                              <li>
                                <Link to="/About">Introduction</Link>
                              </li>
                              <li>
                                <Link to="/Missionvision">Mission Statement</Link>
                              </li>
                              <li>
                                <Link to="/Whyus">Why Choose Us</Link>
                              </li>
                              <li>
                                <Link to="/Faqs">FAQ's</Link>
                              </li>
                              <li>
                                <Link to="/Careers">Join Us</Link>
                              </li>
                            
                            </ul>
                          </li>
                         
                          <li className="dropdown">
                            <Link to="#">Freight Solutions</Link>
                            <ul>
                            <li>
                                <Link to="/Air">Air Freight</Link>
                              </li>
                              <li>
                                <Link to="/Sea">Sea Freight</Link>
                              </li>
                              <li>
                                <Link to="/Surface">Surface Freight</Link>
                              </li>
                              <li>
                                <Link to="/Custom">Custom Clearance</Link>
                              </li>
                              <li>
                                <Link to="/Courier">Courier Freight</Link>
                              </li>

                              <li>
                                <Link to="/Warehousing">Warehousing & Packaging</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Industries</Link>
                            <ul>
                            <li>
                                <Link to="/Automotive">Automotive</Link>
                              </li>
                              <li>
                                <Link to="/Chemicals">Chemicals & Petrochemicals</Link>
                              </li>
                              <li>
                                <Link to="/Food">Food & Beverages</Link>
                              </li>
                              <li>
                                <Link to="/Fruits">Fruits & Vegetables</Link>
                              </li>
                              <li>
                                <Link to="/Mining">Mining & Minerals</Link>
                              </li>
                              <li>
                                <Link to="/Pharmaceuticals">Pharmaceuticals</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Resources</Link>
                            <ul>
                              <li>
                                <Link to="/Incoterms">Shipping Incoterms</Link>
                              </li>
                              <li>
                                <Link to="/Container">Container Specification</Link>
                              </li>
                              <li>
                                <Link to="/Faqs">Frequently Asked Questions</Link>
                              </li>
                              <li>
                                <Link to="/Dlabels">Dangerous Goods Labels</Link>
                              </li><li>
                                <Link to="/Privacy">Privacy Policy</Link>
                              </li>
                              <li>
                                <Link to="/Terms">Terms & Conditions</Link>
                              </li>
                            </ul>
                          </li>
                          
                          <li>
                            <Link to="/Contact">Contact</Link>
                          </li>
                          <li>
                            <Link to="/Getquote">Get Quote</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div className="stricky-header stricky-header__one stricked-menu main-menu">
          <div className="sticky-header__content" />
          {/* /.sticky-header__content */}
        </div>
      </div>
    </>
  );
};

export default Header;
