import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Chemicals & Petrochemicals</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Chemicals & Petrochemicals</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            {/*Start Blog Details Content*/}
            <div className="col-xl-8">
              <div className="blog-details__content">
                <div className="blog-list-page__single">
                  <div className="blog-list-page__single-img">
                    <img src="assets/images/Chemicals & Petrochemicals.jpg" alt="#" />
                    <div className="overlay-text">
                    Industry
                    </div>
                  </div>
                  <div className="blog-list-page__single-content">
                    <div className="blog-list-page__single-content-top">
                     
                     
                    </div>
                    <h2>Chemicals & Petrochemicals</h2>
                  </div>
                </div>
                <div className="blog-details__content-text1">
                  <p className="text1"> {companyname} specializes in offering meticulous, exacting, and robust procedures designed to securely transport hazardous and potentially dangerous goods, such as chemicals and petrochemicals. With our extensive experience as experts in the transportation of hazardous cargoes, you can trust {companyname} to oversee the safe shipment of your cargo while ensuring full compliance with all pertinent regulations. Additionally, our expertise extends to managing unforeseen contingencies, providing you with comprehensive support no matter where your shipments are destined across the world.

<p>Our commitment to safety and compliance is unwavering, and we take great care to safeguard not only your cargo but also the environment and communities through which it travels. We understand the unique challenges and risks associated with hazardous materials, and our team is well-prepared to navigate them expertly. When you choose {companyname}, you're choosing a partner dedicated to the secure and responsible transportation of your valuable cargo, ensuring peace of mind throughout the journey.</p> </p>
                  
                </div>
               
              </div>
            </div>
            {/*End Blog Details Content*/}
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}
               
                <div className="sidebar__single sidebar__category wow animated fadeInUp" data-wow-delay="0.2s">
                  <div className="title-box">
                    <h2>Other Industries</h2>
                  </div>
                  <ul className="sidebar__category-list list-unstyled">
                    <li className=""><Link to="/Automotive">Automotive </Link></li>
                    <li className="active "><Link to="/Chemicals">Chemicals & Petrochemicals </Link></li>
                    <li className=" "><Link to="/Food">Food & Beverages </Link></li>
                    <li className=" "><Link to="/Fruits">Fruits & Vegetables </Link></li>
                    <li className=" "><Link to="/Mining">Mining & Minerals </Link></li>
                    <li className=" "><Link to="/Pharmaceuticals">Pharmaceuticals </Link></li>
                    
                  </ul>
                </div>
               
              </div>
            </div>
            {/*End Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
