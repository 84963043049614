import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Automotive</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Automotive</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            {/*Start Blog Details Content*/}
            <div className="col-xl-8">
              <div className="blog-details__content">
                <div className="blog-list-page__single">
                  <div className="blog-list-page__single-img">
                    <img src="assets/images/Automotive.jpg" alt="#" />
                    <div className="overlay-text">Industry</div>
                  </div>
                  <div className="blog-list-page__single-content">
                    <div className="blog-list-page__single-content-top"></div>
                    <h2>Automotive</h2>
                  </div>
                </div>
                <div className="blog-details__content-text1">
                  <p className="text1">
                    {" "}
                    <p>
                      Whether you're engaged in shipping essential production or
                      service components, including automobiles, trucks, or the
                      latest electric vehicles, the significance of a dependable
                      and seasoned shipping partner cannot be overstated. In the
                      complex world of freight forwarding, such a partner stands
                      as a critical link in your supply chain, ensuring
                      uninterrupted operations. The automotive industry,
                      evolving steadily toward a more sustainable and
                      eco-friendly future, necessitates an agile and transparent
                      supply chain solution. {companyname}, as your trusted partner, is
                      poised to deliver precisely that – a swift, adaptable, and
                      transparent logistics solution tailored to the evolving
                      needs of the automotive sector.
                    </p>
                    <p>
                      In an era marked by changing regulations, environmental
                      concerns, and the pursuit of sustainability, {companyname} remains
                      committed to supporting the automotive industry's
                      transition. We understand that the industry's landscape is
                      transforming, and we are prepared to provide the necessary
                      logistics infrastructure to navigate this change
                      seamlessly. Our goal is to empower your supply chain with
                      the flexibility to adapt to emerging trends, ensuring the
                      efficient transportation of automotive products, whether
                      traditional or electric, across the globe. Count on {companyname} to
                      be your partner in propelling your automotive business
                      forward into a greener, more sustainable future.
                    </p>{" "}
                  </p>
                </div>
              </div>
            </div>
            {/*End Blog Details Content*/}
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}

                <div
                  className="sidebar__single sidebar__category wow animated fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="title-box">
                    <h2>Other Industries</h2>
                  </div>
                  <ul className="sidebar__category-list list-unstyled">
                    <li className="active">
                      <Link to="/Automotive">Automotive </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Chemicals">Chemicals & Petrochemicals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Food">Food & Beverages </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Fruits">Fruits & Vegetables </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Mining">Mining & Minerals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Pharmaceuticals">Pharmaceuticals </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
