import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Mining & Minerals</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Mining & Minerals</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            {/*Start Blog Details Content*/}
            <div className="col-xl-8">
              <div className="blog-details__content">
                <div className="blog-list-page__single">
                  <div className="blog-list-page__single-img">
                    <img src="assets/images/Mining & Minerals.jpg" alt="#" />
                    <div className="overlay-text">Industry</div>
                  </div>
                  <div className="blog-list-page__single-content">
                    <div className="blog-list-page__single-content-top"></div>
                    <h2>Mining & Minerals</h2>
                  </div>
                </div>
                <div className="blog-details__content-text1">
                  <p className="text1">
                    {" "}
                    For numerous decades, {companyname} has been a trusted
                    facilitator in connecting the mining and minerals extraction
                    sectors with customer markets spanning the globe. Given the
                    pivotal role that mining and mineral products play across
                    various industries, including construction, technology,
                    manufacturing, and energy production, {companyname}'s
                    proficiency in delivering swift and dependable transit times
                    plays a crucial role in ensuring the seamless operation of
                    your supply chain.
                    <p>
                      As the global demand for essential commodities like
                      graphite, iron, steel, copper, and ore continues to surge,
                      it becomes increasingly vital to collaborate with a
                      shipping partner well-versed in the unique challenges
                      faced by the industry. For instance, instances of natural
                      disasters or geopolitical tensions can disrupt your supply
                      chain. By aligning with an industry expert like{" "}
                      {companyname}, you gain a valuable ally who can help you
                      navigate these obstacles, minimize delays, and swiftly get
                      your supply chain back on course.
                    </p>{" "}
                  </p>
                </div>
              </div>
            </div>
            {/*End Blog Details Content*/}
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}

                <div
                  className="sidebar__single sidebar__category wow animated fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="title-box">
                    <h2>Other Industries</h2>
                  </div>
                  <ul className="sidebar__category-list list-unstyled">
                    <li className="">
                      <Link to="/Automotive">Automotive </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Chemicals">Chemicals & Petrochemicals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Food">Food & Beverages </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Fruits">Fruits & Vegetables </Link>
                    </li>
                    <li className=" active">
                      <Link to="/Mining">Mining & Minerals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Pharmaceuticals">Pharmaceuticals </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
