import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Fruits & Vegetables</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Fruits & Vegetables</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            {/*Start Blog Details Content*/}
            <div className="col-xl-8">
              <div className="blog-details__content">
                <div className="blog-list-page__single">
                  <div className="blog-list-page__single-img">
                    <img src="assets/images/Fruits & Vegetables.jpg" alt="#" />
                    <div className="overlay-text">Industry</div>
                  </div>
                  <div className="blog-list-page__single-content">
                    <div className="blog-list-page__single-content-top"></div>
                    <h2>Fruits & Vegetables</h2>
                  </div>
                </div>
                <div className="blog-details__content-text1">
                  <p className="text1">
                    {" "}
                    In today's consumer-driven market, the demand for a
                    consistent supply of fresh, delicious, and visually
                    appealing fruits has never been higher. However,
                    successfully transporting these delicate and perishable
                    goods from the farm to their final destination in impeccable
                    condition, while preserving their taste, freshness, texture,
                    and aroma, presents a formidable challenge. It necessitates
                    a combination of speed, adaptability, and state-of-the-art
                    temperature control technology to ensure that these fruits
                    reach consumers in the best possible condition.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/*End Blog Details Content*/}
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}

                <div
                  className="sidebar__single sidebar__category wow animated fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="title-box">
                    <h2>Other Industries</h2>
                  </div>
                  <ul className="sidebar__category-list list-unstyled">
                    <li className="">
                      <Link to="/Automotive">Automotive </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Chemicals">Chemicals & Petrochemicals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Food">Food & Beverages </Link>
                    </li>
                    <li className=" active">
                      <Link to="/Fruits">Fruits & Vegetables </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Mining">Mining & Minerals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Pharmaceuticals">Pharmaceuticals </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
