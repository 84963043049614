import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Pharmaceuticals</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Pharmaceuticals</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            {/*Start Blog Details Content*/}
            <div className="col-xl-8">
              <div className="blog-details__content">
                <div className="blog-list-page__single">
                  <div className="blog-list-page__single-img">
                    <img src="assets/images/Pharmaceuticals.jpg" alt="#" />
                    <div className="overlay-text">Industry</div>
                  </div>
                  <div className="blog-list-page__single-content">
                    <div className="blog-list-page__single-content-top"></div>
                    <h2>Pharmaceuticals</h2>
                  </div>
                </div>
                <div className="blog-details__content-text1">
                  <p className="text1">
                    {" "}
                    A growing number of companies are embracing pharmaceutical
                    shipping as a means to guarantee the swift and secure
                    delivery of crucial medicines and essential goods. The
                    precision in shipping pharmaceutical products is of
                    paramount importance due to the high sensitivity of these
                    products concerning both time and quality assurance.
                    <p>
                      The logistics involved in pharmaceutical transportation
                      necessitate strict adherence to regulatory codes to ensure
                      the punctual and damage-free delivery of all products,
                      including medications and vaccines. At {companyname}, we
                      take pride in our commitment to upholding Good
                      Distribution Practice (GDP) compliant procedures. We offer
                      dependable and cost-effective deliveries to destinations
                      worldwide, ensuring that your pharmaceutical shipments are
                      handled with the utmost care and compliance.
                    </p>
                    <p>
                      When you choose {companyname} for your pharmaceutical
                      shipping needs, you gain access to our extensive global
                      network, seamlessly connecting all major trading centers
                      worldwide. This network empowers you to provide
                      pharmaceutical support precisely where and when it is
                      needed, regardless of the destination.
                    </p>{" "}
                  </p>
                </div>
              </div>
            </div>
            {/*End Blog Details Content*/}
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}

                <div
                  className="sidebar__single sidebar__category wow animated fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="title-box">
                    <h2>Other Industries</h2>
                  </div>
                  <ul className="sidebar__category-list list-unstyled">
                    <li className="">
                      <Link to="/Automotive">Automotive </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Chemicals">Chemicals & Petrochemicals </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Food">Food & Beverages </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Fruits">Fruits & Vegetables </Link>
                    </li>
                    <li className=" ">
                      <Link to="/Mining">Mining & Minerals </Link>
                    </li>
                    <li className="active ">
                      <Link to="/Pharmaceuticals">Pharmaceuticals </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
