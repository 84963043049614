import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Careers() {
  const [fullname, setFullname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [position, setPosition] = useState("");
  const [coverletter, setCoverletter] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [result, setResult] = useState("");

  const handlefullnameChange = (e) => {
    setFullname(e.target.value);
  };
  const handleemailidChange = (e) => {
    setEmailid(e.target.value);
  };
  const handlephoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlequalificationChange = (e) => {
    setQualification(e.target.value);
  };
  const handlepositionChange = (e) => {
    setPosition(e.target.value);
  };
  const handlecoverletterChange = (e) => {
    setCoverletter(e.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    console.log(selectedFile);

    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Join Us</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Join Us</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="contact-page">
        <div className="container">
          <div className="contact-page__top">
            <div className="title text-center">
              <h2>Opportunities at {companyname}</h2>
              <p>
              Freight forwarding is a dynamic industry. Join us to be a part of the innovation that's shaping the future of logistics.
              </p>
            </div>
          </div>
          <div className="contact-page__bottom">
            <div
              className="contact-page__bottom-pattern"
              style={{
                backgroundImage:
                  "url(assets/images/pattern/contact-pattern.jpg)",
              }}
            >
              {" "}
            </div>
            <div className="contact-page__bottom-inner">
              <form
                action="/php/thankyou-careers.php"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row p-2">
                  <div className="col-md-4">
                    <div className="contact-page__form-input-box">
                      <input
                        onChange={(event) => handlefullnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={fullname}
                        id="fullname"
                        name="fullname"
                        placeholder="Your Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="contact-page__form-input-box">
                      <input
                        onChange={(event) => handleemailidChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={emailid}
                        id="emailid"
                        name="emailid"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="contact-page__form-input-box">
                      <input
                        onChange={(event) => handlephoneChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        value={phone}
                        id="handlephoneChange"
                        name="phone"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                </div>
                <label>Highest Qualification</label>
                <div className="col-12 p-2">
                  <div className="contact-page__form-input-box">
                    <input
                      onChange={(event) => handlequalificationChange(event)}
                      type="text"
                      style={{
                        width: "100%",
                        padding: "10px",
                        margin: "6px 0",
                      }}
                      value={qualification}
                      id="qualification"
                      name="qualification"
                      placeholder="Qualification"
                      required
                    />
                  </div>
                </div>
                <label>Position Applied for</label>
                <div className="col-12 p-2">
                  <div className="contact-page__form-input-box">
                    <input
                      onChange={(event) => handlepositionChange(event)}
                      type="text"
                      style={{
                        width: "100%",
                        padding: "10px",
                        margin: "6px 0",
                      }}
                      value={position}
                      id="position"
                      name="position"
                      placeholder="position"
                      required
                    />
                  </div>
                </div>
                <label>Add cover letter</label>
                <div className="col-12 p-2">
                  <div className="contact-page__form-input-box">
                    <textarea
                      onChange={(event) => handlecoverletterChange(event)}
                      style={{
                        width: "100%",
                        padding: "10px",
                        margin: "6px 0",
                      }}
                      placeholder="Leave a message here"
                      value={coverletter}
                      id="coverletter"
                      name="coverletter"
                      required
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    name="submitcareers"
                    defaultValue="SEND"
                    className="thm-btn"
                  >
                    Apply
                  </button>
                </div>
                <br />
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
