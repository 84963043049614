import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Air Freight</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Air Freight</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="services-details">
        <div className="container">
          <div className="row">
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}
                <div className="sidebar__single sidebar__services wow animated fadeInUp" data-wow-delay="0.1s">
                  <div className="title-box">
                    <h2>Services List</h2>
                  </div>
                  <div className="sidebar__services-box">
                    <ul className="sidebar__services-box-list clearfix">
                      <li><Link className="active" to="/Air">Air Freight<span className="icon-diagonal-arrow" /></Link>
                      </li>
                      <li><Link className=" " to="/Sea">Sea Freight<span className="icon-diagonal-arrow" /></Link>
                      </li>
                      <li><Link className=" " to="/Surface">Surface Freight<span className="icon-diagonal-arrow" /></Link>
                      </li>
                      <li><Link className=" " to="/Custom">Custom Clearance<span className="icon-diagonal-arrow" /></Link>
                      </li>
                      <li><Link className=" " to="/Courier">Courier Freight<span className="icon-diagonal-arrow" /></Link>
                      </li>
                      <li><Link className=" " to="/Warehousing">Warehousing & Packaging<span className="icon-diagonal-arrow" /></Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
               
              </div>
            </div>
            {/*End Sidebar*/}
            {/*Start Services Details Content*/}
            <div className="col-xl-8">
              <div className="services-details__content">
                <div className="services-details__content-text1">
                  <h2>Air Freight</h2>
                 
                  <div className="img-box">
                    <img src="assets/images/Air Freight.jpg" alt="#" />
                  </div>
                  <p className="text2">  {" "}
                   
                   <p>
                     We have the capability to organize your shipments
                     globally, utilizing various modes of transportation, and
                     providing expert advice on the most cost-effective and
                     reliable routes to ensure your valuable orders reach your
                     clients both economically and promptly.
                   </p>
                   <p>
                     We diligently monitor carrier statuses to ensure your
                     shipments arrive at their destinations on schedule,
                     regardless of the cargo's size, type, or value. We are
                     committed to helping you identify the most efficient,
                     secure, and economically viable shipping options.
                   </p>
                   <p>
                     Thanks to our high shipping volumes, we are able to offer
                     discounted spot tariffs to large and frequent shippers,
                     making our services even more cost-effective for you.
                   </p>
                   <p>Our Service Details:</p>
                   <ul>
                     <li>Consolidation</li>
                     <li>Direct shipments</li>
                     <li>DAP/DDP services</li>
                     <li>Dangerous & ODC cargo</li>
                     <li>Online Cargo tracking</li>
                     <li>Project cargo</li>
                   </ul></p>
                </div>
                
              </div>
            </div>
            {/*End Services Details Content*/}
          </div>
        </div>
      </section>
      <Footer /> 
    </>
  );
}
