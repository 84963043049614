import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Mission Statement</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Mission Statement</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="intro-one">
          <div
            className="intro-one__pattern"
            style={{
              backgroundImage:
                "url(assets/images/pattern/intro-v1-pattern.jpg )",
            }}
          />
          <div className="container">
            <ul className="row">
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6 border-left">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon">
                    <span className="fa fa-trophy" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Expertise</Link>
                    </h2>
                    <p>
                      Based on our extensive experience, logistics management
                      plays a pivotal role in the logistics sector...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6 border-bottom1">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon">
                    <span className="fa fa-handshake" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Motto</Link>
                    </h2>
                    <p>
                      No matter your global shipping requirements, we are
                      prepared with a tailored local solution...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6 border-left border-bottom1 border2">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon">
                    <span className="fa fa-bullseye" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Mission</Link>
                    </h2>
                    <p>
                      Our pledge is to furnish our clients with a comprehensive
                      range of cost-efficient forwarding solutions...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon style2">
                    <span className="fa fa-eye" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Vision</Link>
                    </h2>
                    <p>
                      We are dedicated to delivering top-tier customer service
                      in the field of freight forwarding. Allow us to provide...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
            </ul>
          </div>
        </section>
        {/*End Intro One*/}
        {/*Start Services One*/}
        <section className="services-one">
          <div className="container">
            <div className="row">
              {/*Start Services One Single*/}
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Air Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-plane" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Air">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Air">Air Freight</Link>
                      </h2>
                      <p>{"content"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Sea Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-ship" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Sea">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Sea">Sea Freight</Link>
                      </h2>
                      <p>{"content"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Surface Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-truck" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Surface">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Surface">Surface Freight</Link>
                      </h2>
                      <p>{"content"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
     

      <Footer />
    </>
  );
}
