import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
     
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Shipping Incoterms</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Shipping Incoterms</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className=" g-5 align-items-center mb-5">
            <div
              className=" m-5 wow fadeIn text-center"
              data-wow-delay="0.3s"
              style={{ width: "1000px" }}
            >
              <h1 className="display-6 mb-3 p-4">Shipping Incoterms</h1>
            </div>
            <h4> INCOTERMS® 2020 now are in 2 classes: </h4> <br />
            <p>
              {" "}
              For any mode of transport and For sea and inland waterway
              shipping. Incoterms are briefly described here, though we
              recommend a full and comprehensive study of the term/s is made to
              be aware of all the obligations and risks to be undertaken. <br />
              <br />
            </p>
            <h4>
              FOR ANY MODE OF TRANSPORT
              <p />
              <br />
            </h4>
            <p></p>
            <li>
              {" "}
              EXW = Ex Works – seller makes goods available at his warehouse,
              the carriage is arranged/paid by buyer.
            </li>
            <li>
              {" "}
              FCA = Free Carrier – seller arranges/pays for pre-carriage to
              named place in country of export.
            </li>
            <li>
              {" "}
              CPT = Carriage Paid To – seller arranges/pays for carriage to a
              named place of destination.
            </li>
            <li>
              {" "}
              CIP = Carriage and Insurance Paid – as CPT, plus seller insures
              the freight.
            </li>
            <li>
              {" "}
              DAP = Delivered at Place – seller arranges/pays for carriage to an
              agreed place of destination.
            </li>
            <li>
              {" "}
              DPU = Delivered at Place Unloaded – Seller delivers the goods and
              transfers risk to the buyer when goods are unloaded from transport
              at the agreed place of destination.
            </li>
            <li>
              DDP = Delivered, Duty Paid – seller arranges/pays for carriage to
              an agreed place of destination including all import duties,
              customs formalities and local taxes.
              <p /> <br />
              <br />
              <h4> FOR SEA AND INLAND WATERWAY TRANSPORTATION </h4>
              <p></p>
            </li>
            <li>
              FAS = Free Alongside Ship: seller arranges/pays for pre-carriage
              to a named port of shipment
            </li>
            <li>
              {" "}
              FOB = Free on Board: seller arranges for pre-carriage to a named
              port of shipment paying for all
            </li>
            <li>costs to on board vessel.</li>
            <li>
              **CFR = Cost and Freight: seller arranges/pays for carriage to
              arrival destination port.
            </li>
            <li>
              {" "}
              **CIF = Cost Insurance and Freight: as CFR plus seller insures the
              freight. <br />
              <br />
              **Please note that CFR and CIF terms are for sea / inland waterway
              transportation and are therefore not suitable terms for
              containerized transport. CPT/CIP should be used instead.
              <p />
            </li>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
