import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Warehousing & Packaging</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Warehousing & Packaging</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="services-details">
        <div className="container">
          <div className="row">
            {/*Start Sidebar*/}
            <div className="col-xl-4">
              <div className="sidebar">
                {/*Start Sidebar Single*/}
                <div
                  className="sidebar__single sidebar__services wow animated fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="title-box">
                    <h2>Services List</h2>
                  </div>
                  <div className="sidebar__services-box">
                    <ul className="sidebar__services-box-list clearfix">
                      <li>
                        <Link className="" to="/Air">
                          Air Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Sea">
                          Sea Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Surface">
                          Surface Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Custom">
                          Custom Clearance
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className=" " to="/Courier">
                          Courier Freight
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                      <li>
                        <Link className="active " to="/Warehousing">
                          Warehousing & Packaging
                          <span className="icon-diagonal-arrow" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*End Sidebar*/}
            {/*Start Services Details Content*/}
            <div className="col-xl-8">
              <div className="services-details__content">
                <div className="services-details__content-text1">
                  <h2>Warehousing & Packaging</h2>

                  <div className="img-box">
                    <img
                      src="assets/images/Warehousing & Packaging.jpg"
                      alt="#"
                    />
                  </div>
                  <p className="text2">
                    <p>
                      We provide a comprehensive, one-stop solution for
                      essential warehouse services, strategically located in
                      proximity to automotive, engineering, pharmaceutical, and
                      IT hubs. With our deep knowledge and expertise in
                      warehouse management systems, we offer top-tier service
                      and security for your cargo. Our facilities include
                      everything required for efficient loading/unloading,
                      physical scanning, and proper stacking of goods, in
                      addition to transportation services for convenient pickup
                      and delivery.
                    </p>
                    <p>Our Service Details:</p>
                    <ul>
                      <li>Consolidation</li>
                      <li>De-consolidation</li>
                      <li>Packaging</li>
                      <li>Quality Control</li>
                      <li>Storage</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            {/*End Services Details Content*/}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
