import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="shape1">
          <img src="assets/images/shapes/page-header-shape1.png" alt="#" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Why Choose Us</h2>
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Why Choose Us</li>
            </ul>
          </div>
        </div>
      </section>
     
        <section className="therapy-one">
          <div className="container">
            <div className="therapy-one__inner">
              <div
                className="therapy-one__pattern"
                style={{
                  backgroundImage:
                    "url(assets/images/pattern/therapy-v1-pattern.png)",
                }}
              />
              <div
                className="therapy-one__bg"
                style={{
                  backgroundImage: "url(assets/images/About2.jpg)",
                }}
              />
              <div
                className="therapy-one__img1 wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <img
                  src="assets/images/resources/therapy-v1-img1.png"
                  alt="#"
                />
              </div>
              <div className="shape1" />
              <div className="shape2" />
              <div className="shape3" />
              <div className="row">
                {/*Start Therapy One Left*/}
                <div className="col-xl-5">
                  <div className="therapy-one__left">
                    <div className="sec-title">
                      <div className="sec-title__tagline">
                        <h6>Why Choose {companyname}</h6>
                      </div>
                      <h2 className="sec-title__title">
                        Delivering More than Freight, Delivering Trust
                      </h2>
                    </div>
                    <ul className="therapy-one__left-list">
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="content-box">
                          <p>
                            Despite being a relatively new entrant in the
                            industry, every individual within the {companyname} team
                            nurtures a profound aspiration to become a true
                            expert within this domain. We enthusiastically
                            invite and eagerly await your invaluable inquiries.
                            Our commitment extends beyond mere words; it is an
                            unwavering dedication to providing you with the
                            highest caliber of freight forwarding customer
                            service. Our aim is to leverage our growing
                            experience and extensive knowledge to assist you in
                            making well-informed decisions that not only
                            optimize time but also minimize costs, ensuring the
                            most efficient and cost-effective choices for your
                            shipping needs.
                          </p>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                {/*End Therapy One Left*/}
                {/*Start Therapy One Right*/}
                <div className="col-xl-7">
                  <div className="therapy-one__right clearfix">
                    <div className="therapy-one__right-content">
                      <div
                        className="therapy-one__right-content-bg"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/therapy-v1-bg2.jpg)",
                        }}
                      ></div>
                      <div className="inner">
                        <div className="content-box">
                          <h2>Navigating Challenges with Ease</h2>
                          <p>
                            our commitment to proactive problem-solving and
                            adaptability, ensuring smooth progress in a dynamic
                            business landscape.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Therapy One Right*/}
              </div>
            </div>
          </div>
        </section>
        {/*End Therapy One */}
                {/*Start Case One*/}
                <section className="case-one">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <h6>All Services</h6>
              </div>
              <h2 className="sec-title__title">Our Freight Solutions</h2>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="case-one__inner">
                  <div
                    className="owl-carousel owl-theme thm-owl__carousel case-one__carousel"
                    data-owl-options='{
                                "loop": true,
                                "autoplay": true,
                                "margin": 30,
                                "nav": false,
                                "dots": true,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-next\"></span>"],
                                "responsive": {
                                        "0": {
                                            "items": 1
                                        },
                                        "768": {
                                            "items": 2
                                        },
                                        "992": {
                                            "items": 2
                                        },
                                        "1200": {
                                            "items": 4
                                        }
                                    }
                                }'
                  >
                    {/*Start Case One Single*/}
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Air Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Air">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Air">Air Freight</Link>
                          </h2>
                          <p>{" content"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Sea Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Sea">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Sea">Sea Freight</Link>
                          </h2>
                          <p>{" content"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Surface Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Surface">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Surface">Surface Freight</Link>
                          </h2>
                          <p>{" content"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Custom Clearance.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Custom">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Custom">Custom Clearance</Link>
                          </h2>
                          <p>{" content"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Courier Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Courier">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Courier">Courier Freight</Link>
                          </h2>
                          <p>{" content"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img
                          src="assets/images/Warehousing & Packaging.jpg"
                          alt="#"
                        />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Warehousing">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Warehousing">
                              Warehousing & Packaging
                            </Link>
                          </h2>
                          <p>{" content"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Case One*/}

      <Footer />
    </>
  );
}
