import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Air from "./pages/Air";
import Multimodal from "./pages/Multimodal";
import Chemicals from "./pages/Chemicals";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Industrial from "./pages/Industrial";
import Mining from "./pages/Mining";
import Cargo from "./pages/Cargo";
import High from "./pages/High";
import Retail from "./pages/Retail";

import Courier from "./pages/Courier";
import Track from "./pages/Track";
import Trace from "./pages/Trace";
import Surface from "./pages/Surface";
import Fruits from "./pages/Fruits";
import Automotive from "./pages/Automotive";
import Healthcare from "./pages/Healthcare";
import Custom from "./pages/Custom";
import Intermodal from "./pages/Intermodal";
import Warehousing from "./pages/Warehousing";
import Consolidation from "./pages/Consolidation";
import Wood from "./pages/Wood";
import Food from "./pages/Food";
import Total from "./pages/Total";
import Container from "./pages/Container";
import Whyus from "./pages/Whyus";
import Careers from "./pages/Careers";
import Incoterms from "./pages/Incoterms";
import Dlabels from "./pages/Dlabels";
import Metric from "./pages/Metric";
import Missionvision from "./pages/Missionvision";
import Faqs from "./pages/Faqs";
import Industries from "./pages/Industries";
import Requestquote from "./pages/Requestquote";
import Glossary from "./pages/Glossary";
import Pharmaceuticals from "./pages/Pharmaceuticals";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Industrial" element={<Industrial />} />
          <Route path="/Glossary" element={<Glossary />} />
          <Route path="/Pharmaceuticals" element={<Pharmaceuticals />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Multimodal" element={<Multimodal />} />
          <Route path="/Requestquote" element={<Requestquote />} />
          <Route path="/Chemicals" element={<Chemicals />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Surface" element={<Surface />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Mining" element={<Mining />} />
          <Route path="/Cargo" element={<Cargo />} />
          <Route path="/High" element={<High />} />
          <Route path="/Retail" element={<Retail />} />
          <Route path="/Courier" element={<Courier />} />
          <Route path="/Track" element={<Track />} />
          <Route path="/Trace" element={<Trace />} />
          <Route path="/Fruits" element={<Fruits />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/Custom" element={<Custom />} />
          <Route path="/Intermodal" element={<Intermodal />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Consolidation" element={<Consolidation />} />
          <Route path="/Wood" element={<Wood />} />
          <Route path="/Food" element={<Food />} />
          <Route path="/Total" element={<Total />} />
          <Route path="/Container" element={<Container />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Missionvision" element={<Missionvision />} />
          <Route path="/Incoterms" element={<Incoterms />} />
          <Route path="/Metric" element={<Metric />} />
          <Route path="/Dlabels" element={<Dlabels />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Industries" element={<Industries />} />
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
