import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />{" "}
      <div className="page-wrapper">

        {/* /.stricky-header */}
        {/*Start Main Slider One*/}
        <section className="main-slider main-slider-one w3-banner jarallax">
          <video
          autoPlay
          loop
          muted
          >
            <source src="assets/images/video.mp4">
            </source>
          </video>

        </section>
        {/*End Main Slider One*/}
        {/*Start Intro One*/}
        <section className="intro-one">
          <div
            className="intro-one__pattern"
            style={{
              backgroundImage:
                "url(assets/images/pattern/intro-v1-pattern.jpg )",
            }}
          />
          <div className="container">
            <ul className="row">
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6 border-left">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon">
                    <span className="fa fa-trophy" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Expertise</Link>
                    </h2>
                    <p>
                      Based on our extensive experience, logistics management
                      plays a pivotal role in the logistics sector...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6 border-bottom1">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon">
                    <span className="fa fa-handshake" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Motto</Link>
                    </h2>
                    <p>
                      No matter your global shipping requirements, we are
                      prepared with a tailored local solution...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6 border-left border-bottom1 border2">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon">
                    <span className="fa fa-bullseye" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Mission</Link>
                    </h2>
                    <p>
                      Our pledge is to furnish our clients with a comprehensive
                      range of cost-efficient forwarding solutions...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
              {/*Start Intro One Single*/}
              <li className="col-xl-3 col-lg-6 col-md-6">
                <div className="intro-one__single">
                  <div className="intro-one__single-icon style2">
                    <span className="fa fa-eye" />
                  </div>
                  <div className="intro-one__single-content">
                    <h2>
                      <Link to="#">Our Vision</Link>
                    </h2>
                    <p>
                      We are dedicated to delivering top-tier customer service
                      in the field of freight forwarding. Allow us to provide...
                    </p>
                  </div>
                </div>
              </li>
              {/*End Intro One Single*/}
            </ul>
          </div>
        </section>
        {/*End Intro One*/}
        {/*Start Services One*/}
        <section className="services-one">
          <div className="container">
            <div className="row">
              {/*Start Services One Single*/}
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Air Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-plane" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Air">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Air">Air Freight</Link>
                      </h2>
                      <p> We have the capability to organize your shipments
                     globally, utilizing various modes of transportation, and
                     providing expert advice on the most cost-effective and...</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Sea Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-ship" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Sea">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Sea">Sea Freight</Link>
                      </h2>
                      <p> We have the capability to arrange both Full Container Load
                      (FCL) and Less than Container Load (LCL) shipments for our
                      global customers. Our significant advantage lies in our...</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow animated fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <div className="inner">
                      <img src="assets/images/Surface Freight.jpg" alt="#" />
                    </div>
                    <div className="services-one__single-img-icon">
                      <div className="services-one__single-img-icon-inner">
                        <span className="fa fa-truck" />
                      </div>
                    </div>
                  </div>
                  <div className="services-one__single-content clearfix">
                    <div className="btn-box">
                      <Link to="/Surface">
                        <div className="text-box">Learn More</div>
                        <div className="icon-box">
                          <span className="icon-arrow-right1" />
                        </div>
                      </Link>
                    </div>
                    <div className="services-one__single-content-inner">
                      <h2>
                        <Link to="/Surface">Surface Freight</Link>
                      </h2>
                      <p> Our expertise extends to arranging the transportation of
                      both Full Truck Loads (FTL) and Less Than Truck Loads
                      (LTL) across Singapore, leveraging cutting-edge tracking...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Services One*/}
        {/*Start Why Choose One*/}
        <section className="why-choose-one">
          <div className="shape3 float-bob-x">
            <img src="assets/images/shapes/why-choose-v1-shape2.png" alt="#" />
          </div>
          <div className="shape5">
            <img src="assets/images/shapes/why-choose-v1-shape3.png" alt="#" />
          </div>
          <div className="shape6">
            <img src="assets/images/shapes/why-choose-v1-shape4.png" alt="#" />
          </div>
          <div className="shape7">
            <img src="assets/images/shapes/why-choose-v1-shape5.png" alt="#" />
          </div>
          <div className="container">
            <div className="row">
              {/*Start Why Choose One Img*/}
              <div className="col-xl-6">
                <div className="why-choose-one__img">
                  <div className="shape2" />
                  <div className="shape1">
                    <img
                      src="assets/images/shapes/why-choose-v1-shape1.png"
                      alt="#"
                    />
                  </div>
                  <div className="shape4 float-bob-y">
                    <img
                      src="assets/images/shapes/why-choose-v1-shape2.png"
                      alt="#"
                    />
                  </div>
                  <div className="why-choose-one__img1">
                    <img
                      src="assets/images/About3.jpg"
                      style={{ height: "670px" }}
                      alt="#"
                    />
                  </div>
                  <div
                    className="why-choose-one__img2 wow fadeInRight"
                    data-wow-delay="100ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="inner">
                      <img
                        src="assets/images/About4.jpg"
                        style={{ height: "400px" }}
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*End Why Choose One Img*/}
              {/*Start Why Choose One Content*/}
              <div className="col-xl-6">
                <div className="why-choose-one__content">
                  <div className="sec-title">
                    <h2 className="sec-title__title">
                      Making the World Smaller, Cargo Bigger
                    </h2>
                  </div>
                  <div className="why-choose-one__content-text">
                    <p className="text1">Welcome to {companyname}</p>
                    <p className="text2">
                      {companyname} is an international freight forwarding
                      company, headquartered in Singapore. Our primary mission
                      is to serve the shipping sector by offering a wide range
                      of services, spanning from supplies to maintenance and
                      operations. We have dedicated significant efforts to
                      establish a comprehensive network of high-quality
                      strategic partnerships on a global scale. Our expertise
                      lies in various aspects of the shipping industry,
                      encompassing air, sea, and road transportation.
                    </p>
                  </div>

                  <div className="why-choose-one__content-text2">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="why-choose-one__content-text2-single">
                          <div className="inner">
                            <div className="icon-box">
                              <span className="fa fa-plane" />
                            </div>
                            <div className="title-box">
                              <h2>
                                Amazing <br />
                                Freight Services
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="why-choose-one__content-text2-single">
                          <div className="inner">
                            <div className="icon-box">
                              <span className="fa fa-clock" />
                            </div>
                            <div className="title-box">
                              <h2>24/7 Support</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="why-choose-one__content-text3">
                    <div className="btn-box">
                      <Link to="/About" className="thm-btn">
                        Learn More About {companyname}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*End Why Choose One Content*/}
            </div>
          </div>
        </section>
        {/*End Why Choose One*/}
        {/*Start Case One*/}
        <section className="case-one">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <h6>All Services</h6>
              </div>
              <h2 className="sec-title__title">Our Freight Solutions</h2>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="case-one__inner">
                  <div
                    className="owl-carousel owl-theme thm-owl__carousel case-one__carousel"
                    data-owl-options='{
                                "loop": true,
                                "autoplay": true,
                                "margin": 30,
                                "nav": false,
                                "dots": true,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-next\"></span>"],
                                "responsive": {
                                        "0": {
                                            "items": 1
                                        },
                                        "768": {
                                            "items": 2
                                        },
                                        "992": {
                                            "items": 2
                                        },
                                        "1200": {
                                            "items": 4
                                        }
                                    }
                                }'
                  >
                    {/*Start Case One Single*/}
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Air Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Air">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Air">Air Freight</Link>
                          </h2>
                          <p> We have the capability to organize your shipments
                     globally, utilizing various modes of transportation, and
                     providing expert advice on the most cost-effective and...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Sea Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Sea">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Sea">Sea Freight</Link>
                          </h2>
                          <p> We have the capability to arrange both Full Container Load
                      (FCL) and Less than Container Load (LCL) shipments for our
                      global customers. Our significant advantage lies in our...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Surface Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Surface">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Surface">Surface Freight</Link>
                          </h2>
                          <p> Our expertise extends to arranging the transportation of
                      both Full Truck Loads (FTL) and Less Than Truck Loads
                      (LTL) across Singapore, leveraging cutting-edge tracking...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Custom Clearance.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Custom">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Custom">Custom Clearance</Link>
                          </h2>
                          <p> We possess extensive expertise in customs clearance,
                      covering both Import and Export operations to and from all
                      Ports and Inland Container Depots (ICDs) in Singapore...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img src="assets/images/Courier Freight.jpg" alt="#" />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Courier">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Courier">Courier Freight</Link>
                          </h2>
                          <p>  We offer comprehensive courier services, ensuring the
                      swift transportation of our customers' small cargo from
                      one door to another anywhere in the world. Our unique...</p>
                        </div>
                      </div>
                    </div>
                    <div className="case-one__single">
                      <div className="case-one__single-img">
                        <img
                          src="assets/images/Warehousing & Packaging.jpg"
                          alt="#"
                        />
                      </div>
                      <div className="case-one__single-content">
                        <div className="icon-box">
                          <span className="" />
                        </div>
                        <div className="btn-box">
                          <Link to="/Warehousing">
                            <span className="icon-arrow-right1" />
                          </Link>
                        </div>
                        <div className="case-one__single-content-inner">
                          <h2>
                            <Link to="/Warehousing">
                              Warehousing & Packaging
                            </Link>
                          </h2>
                          <p> We provide a comprehensive, one-stop solution for
                      essential warehouse services, strategically located in
                      proximity to automotive, engineering, pharmaceutical, and...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Case One*/}

        {/*Start Therapy One */}
        <section className="therapy-one">
          <div className="container">
            <div className="therapy-one__inner">
              <div
                className="therapy-one__pattern"
                style={{
                  backgroundImage:
                    "url(assets/images/pattern/therapy-v1-pattern.png)",
                }}
              />
              <div
                className="therapy-one__bg"
                style={{
                  backgroundImage: "url(assets/images/About2.jpg)",
                }}
              />
              <div
                className="therapy-one__img1 wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <img
                  src="assets/images/resources/therapy-v1-img1.png"
                  alt="#"
                />
              </div>
              <div className="shape1" />
              <div className="shape2" />
              <div className="shape3" />
              <div className="row">
                {/*Start Therapy One Left*/}
                <div className="col-xl-5">
                  <div className="therapy-one__left">
                    <div className="sec-title">
                      <div className="sec-title__tagline">
                        <h6>Why Choose {companyname}</h6>
                      </div>
                      <h2 className="sec-title__title">
                        Delivering More than Freight, Delivering Trust
                      </h2>
                    </div>
                    <ul className="therapy-one__left-list">
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="content-box">
                          <p>
                            Despite being a relatively new entrant in the
                            industry, every individual within the {companyname} team
                            nurtures a profound aspiration to become a true
                            expert within this domain. We enthusiastically
                            invite and eagerly await your invaluable inquiries.
                            Our commitment extends beyond mere words; it is an
                            unwavering dedication to providing you with the
                            highest caliber of freight forwarding customer
                            service. Our aim is to leverage our growing
                            experience and extensive knowledge to assist you in
                            making well-informed decisions that not only
                            optimize time but also minimize costs, ensuring the
                            most efficient and cost-effective choices for your
                            shipping needs.
                          </p>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                {/*End Therapy One Left*/}
                {/*Start Therapy One Right*/}
                <div className="col-xl-7">
                  <div className="therapy-one__right clearfix">
                    <div className="therapy-one__right-content">
                      <div
                        className="therapy-one__right-content-bg"
                        style={{
                          backgroundImage:
                            "url(assets/images/backgrounds/therapy-v1-bg2.jpg)",
                        }}
                      ></div>
                      <div className="inner">
                        <div className="content-box">
                          <h2>Navigating Challenges with Ease</h2>
                          <p>
                            our commitment to proactive problem-solving and
                            adaptability, ensuring smooth progress in a dynamic
                            business landscape.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Therapy One Right*/}
              </div>
            </div>
          </div>
        </section>
        {/*End Therapy One */}
        {/*Start Testimonial One*/}

        {/*Start Team One*/}
        <section className="team-one">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <h6>Our Industries</h6>
              </div>
              <h2 className="sec-title__title">Explore Our Industries</h2>
            </div>
            <div className="row">
              {/*Start Team One Single*/}
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Automotive.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Automotive">
                      <div className="title-box text-center">
                        <h2>Automotive</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img
                      src="assets/images/Chemicals & Petrochemicals.jpg"
                      alt="#"
                    />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Chemicals">
                      <div className="title-box text-center">
                        <h2>Chemicals & Petrochemicals</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Food & Beverages.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Food">
                      <div className="title-box text-center">
                        <h2>Food & Beverages</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Fruits & Vegetables.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Fruits">
                      <div className="title-box text-center">
                        <h2>Fruits & Vegetables</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Mining & Minerals.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Mining">
                      <div className="title-box text-center">
                        <h2>Mining & Minerals</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="team-one__single">
                  <div className="team-one__single-img">
                    <img src="assets/images/Pharmaceuticals.jpg" alt="#" />
                  </div>
                  <div className="team-one__single-content">
                    <Link to="/Pharmaceuticals">
                      <div className="title-box text-center">
                        <h2>Pharmaceuticals</h2>
                        <p>Industry</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Team One*/}
      </div>
      <Footer />
    </>
  );
}
